@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

ol,
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0; }

.text--light {
  font-weight: 300; }

.text--regular {
  font-weight: 400; }

.text--semi-bold {
  font-weight: 600; }

.text--bold {
  font-weight: 700; }

.text--black {
  font-weight: 900; }

* {
  box-sizing: border-box; }

body {
  font-family: "Source Sans Pro", sans-serif; }

.layout {
  display: flex;
  min-height: 100vh; }

@media only screen and (max-width: 960px) {
  html {
    font-size: 12px; }
  .layout {
    flex-direction: column; } }

@media only screen and (max-width: 320px) {
  html {
    font-size: 10px; } }

.sidebar {
  background-color: #222f3e;
  box-shadow: 0px 4px 8px rgba(34, 47, 62, 0.5);
  display: flex;
  flex-direction: column;
  min-width: 18rem;
  padding: 2rem 0; }
  .sidebar--centered {
    align-self: center; }
  .sidebar__separator {
    width: 90%;
    margin: 2rem 0;
    border: solid 1px;
    color: #f2f2f2; }
  .sidebar__menu {
    flex: 1 1; }
  .sidebar__lhl {
    margin-top: 2rem; }

.schedule {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2rem 0;
  padding: 0 2rem;
  width: 100%; }

.day-list__item {
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  color: #8395a7;
  height: 5rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .day-list__item:hover {
    background-color: #ee5253;
    cursor: pointer;
    color: #222f3e; }
  .day-list__item--selected {
    background-color: #f2f2f2;
    color: #222f3e; }
    .day-list__item--selected:hover {
      background-color: #f2f2f2; }
  .day-list__item--full {
    opacity: 0.15; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.appointment__add {
  min-height: 11rem;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .appointment__add-button {
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
    opacity: 0.7; }
    .appointment__add-button:hover {
      cursor: pointer;
      opacity: 1; }

.appointment__time {
  display: flex; }

.appointment__separator {
  border: 0;
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
  margin-left: 1rem; }

.appointment__card {
  min-height: 11rem;
  margin-left: 3rem;
  border-radius: 0.5rem;
  padding: 1.2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  color: #fff; }
  .appointment__card-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 50%; }
  .appointment__card-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end; }
  .appointment__card--create {
    background-color: #222f3e;
    display: flex; }
  .appointment__card--show {
    color: #54a0ff;
    background: -webkit-linear-gradient(left, #54a0ff, #54a0ff 8px, rgba(84, 160, 255, 0.15) 0, rgba(84, 160, 255, 0.15));
    background: linear-gradient(90deg, #54a0ff, #54a0ff 8px, rgba(84, 160, 255, 0.15) 0, rgba(84, 160, 255, 0.15)); }
    .appointment__card--show:hover {
      color: #f2f2f2;
      background-color: #54a0ff; }
  .appointment__card--status {
    background-color: #222f3e;
    display: flex;
    justify-content: center;
    align-items: center; }
  .appointment__card--confirm {
    background-color: rgba(238, 82, 83, 0.15);
    color: #ee5253;
    border: 2px solid #ee5253;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
  .appointment__card--error {
    background-color: rgba(255, 159, 67, 0.15);
    color: #ff9f43;
    border: 2px solid #ff9f43;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.appointment:last-of-type .appointment__add,
.appointment:last-of-type .appointment__card {
  display: none; }

.appointment__actions {
  margin-top: 1rem; }
  .appointment__actions-button {
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
    opacity: 0.7;
    margin-right: 0.5rem;
    display: none; }
    .appointment__actions-button:hover {
      cursor: pointer;
      opacity: 1; }
    .appointment__actions-button:last-of-type {
      margin-right: 0; }

.appointment__card:hover .appointment__actions-button {
  display: inline; }

.appointment__create-input {
  background-color: #222f3e;
  border: 0;
  border-bottom: 1px solid;
  color: #fff;
  font-size: 1.5rem;
  height: 2rem;
  margin-bottom: 0.25rem;
  width: 100%; }
  .appointment__create-input:focus {
    outline: none; }

.appointment__status-image {
  margin-right: 2rem;
  -webkit-animation: rotate 1s linear infinite;
          animation: rotate 1s linear infinite; }

.appointment__error-message {
  display: flex;
  flex-direction: column; }

.appointment__error-close {
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  opacity: 0.7;
  align-self: flex-start; }
  .appointment__error-close:hover {
    cursor: pointer;
    opacity: 1; }

.appointment__validation {
  color: #ee5253;
  margin-bottom: 1rem; }

.button {
  border-radius: 1rem;
  border: 0;
  font-size: 1.5rem;
  height: 2rem;
  line-height: 1.2rem;
  margin-right: 1rem;
  width: 8rem;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  color: #fff;
  background-color: transparent; }

.button:hover {
  cursor: pointer;
  color: #000;
  background-color: #fff;
  border: 2px solid transparent; }

.button--danger {
  color: #ee5253;
  border-color: #ee5253;
  background-color: transparent; }

.button--confirm {
  color: #10ac84;
  border-color: #10ac84;
  background-color: transparent; }

.button--danger:hover {
  color: #fff;
  background-color: #ee5253; }

.button--confirm:hover {
  color: #fff;
  background-color: #10ac84; }

.button:last-of-type {
  margin-right: 0; }

.button:focus {
  outline: none; }

.button:disabled {
  border-style: solid;
  border-color: #fff;
  color: #fff;
  background-color: transparent;
  opacity: 0.7; }

.interviewers__item {
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  opacity: 0.7;
  margin-right: 0.5rem; }
  .interviewers__item-image {
    border-radius: 16px; }
  .interviewers__item--selected {
    color: #222f3e;
    background-color: #fff;
    border-radius: 16px;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 2px;
    padding-right: 1rem;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .interviewers__item--selected .interviewers__item-image {
    width: 28px;
    height: 28px;
    margin-right: 0.5rem; }
  .interviewers__item--selected, .interviewers__item:hover {
    cursor: pointer;
    opacity: 1; }

.interviewers__header {
  color: #fff;
  margin-bottom: 0.5rem; }

.interviewers__list {
  display: flex;
  flex-flow: row wrap; }

